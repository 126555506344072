import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NavDash from "../AdminDashboard/NavDash";
import Sidebar from "../AdminDashboard/Sidebar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import cogoToast from "cogo-toast";

const AddSinglePatient = () => {
  const user = useSelector((state) => state.user);
  const [prevBill, setPrevBill] = useState([]);
  const [preBillNumber, setPreBillNumber] = useState('');
  console.log(`User Name: ${user.name}, User ID: ${user.id}`);
  console.log("User State:", user);
  const navigate = useNavigate();
  // const [fields, setFields] = useState([{ procedure: "", quantity: "" }]);
  const [data, setData] = useState({
    hospital_id: user.id,
    hospital_name: user.name,
    type:user.type,
    dept_type: "",
    reg_number: "",
    opd_number: "",
    ipd_number: "",
    bill_number: "",
    patient_name: "",
    age: "",
    gender: "",
    bill_date: "",
    beneficiary_Id: "",
    date_of_treatment: "",
    address: "",
    refferal_id: "",
    doctor_name: "",
    bill_prepared_by: "",
  });
  const [billId, setBillId] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [focusedInputIndex, setFocusedInputIndex] = useState(null);
  

  const [procData, setProcData] = useState({
    procedures: [{ procedure: "", quantity: "" , price : "" }],
  });
console.log(procData)
  const [procDataList, setProcDataList] = useState([]);
  const [showProcedure, setShowProcedure] = useState(false);

  const [toggle, setToggle] = useState(true);
  const Toggle = () => {
    setToggle(!toggle);
  };

  const getAllProcList = async () => {
    try {
      const response = await axios.get(
        "https://cghsbilling.com/api/v1/auth/getAllProcedures"
      );
      console.log(response.data);
      setProcDataList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(data) 

  const dataChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getAllBill = async () => {
    try {
      const response = await axios.get(
        `https://cghsbilling.com/api/v1/auth/getAllPatientBill/${user.id}`
      );
      console.log(response.data);
      setPrevBill(response.data);
    } catch (error) {
      console.log(error);
      cogoToast.error("error in getting bills");
    }
  };

  const handleDateOfTreatmentChange = (date) => {
    setData((prevData) => ({
      ...prevData,
      date_of_treatment: date,
    }));
  };

  const handleChangeProcedure = (index, key, value) => {
    const newProcedures = [...procData.procedures];
    newProcedures[index][key] = value;
  
    setProcData((prevData) => ({
      ...prevData,
      procedures: newProcedures,
    }));

      // Set the currently focused input index
      setFocusedInputIndex(index);

    const userInput = value.toLowerCase();
    const filtered = procedureNames.filter((name) =>
      name.toLowerCase().includes(userInput)
    );
    setFilteredSuggestions(filtered);
    setShowSuggestions(filtered.length > 0);
  };

  const handleChangeQuantity = (index, key, value) => {
    const newProcedures = [...procData.procedures];
    newProcedures[index][key] = value;
    setProcData((prevData) => ({
      ...prevData,
      procedures: newProcedures,
    }));

   
  };
  const handleChangePrice = (index, key, value) => {
    const newProcedures = [...procData.procedures];
    newProcedures[index][key] = value;
    setProcData((prevData) => ({
      ...prevData,
      procedures: newProcedures,
    }));

   
  };

 

  const handleAddProcedure = () => {
    setProcData((prevData) => ({
      ...prevData,
      procedures: [...prevData.procedures, { procedure: "", quantity: "" , price: "" }],
    }));
  };

  const handleRemoveProcedure = (index) => {
    if (procData.procedures && procData.procedures.length > 1) {
      const newProcedures = [...procData.procedures];
      newProcedures.splice(index, 1);
      setProcData((prevData) => ({
        ...prevData,
        procedures: newProcedures,
      }));
    }
  };

  const handleDateChange = (date) => {
    setData((prevData) => ({
      ...prevData,
      bill_date: date,
    }));
  };

  console.log(data);

  const createBillHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://cghsbilling.com/api/v1/auth/addPatient",
        data
      );

      console.log(response);
      setBillId(response.data.user.id);
      cogoToast.success("Bill created successfully");
      // navigate("/admin-dashboard");
      setShowProcedure(true);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(billId);

  const procedureHandler = async (e) => {
    e.preventDefault();
    try {
      const proceduresCopy = [...procData.procedures];

      // Add a new procedure with default values
      // proceduresCopy.push({ procedure: "", quantity: "" });

      const response = await axios.post(
        `https://cghsbilling.com/api/v1/auth/addProcedure/${billId}`,
        proceduresCopy
      );

      console.log(response);
      setProcData({ procedures: response.data.procedures });
      navigate(`/print-report/${billId}`);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllProcList();
    getAllBill();
   
  }, []);


  console.log(procDataList.result);
  const procedureNames = procDataList.result?.map(
    (item) => item.procedure_name
  );
  console.log(procedureNames);

  // useEffect(() => {
  //   console.log("useEffect triggered with procedures:", procData.procedures);
  // }, [procData.procedures]);

  const Suggestions = ({
    filteredSuggestions,
    onSelect,
    maxSuggestions,
    showSuggestions,
    setShowSuggestions,
  }) => {
    const handleSelect = (suggestion) => {
      onSelect(suggestion);
      setShowSuggestions(false); // Hide suggestions after selecting an item
    };

    return (
      <ul>
        {showSuggestions &&
          filteredSuggestions
            .slice(0, maxSuggestions)
            .map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSelect(suggestion)}
                className="shadow p-3"
              >
                {suggestion}
              </li>
            ))}
      </ul>
    );
  };

  const handleSelectProcedure = (index, selectedProcedure) => {
    const newProcedures = [...procData.procedures];

    newProcedures[index].procedure = selectedProcedure;

    const matchedItem = procDataList.result?.find((item) => item.procedure_name == newProcedures[index].procedure);
    console.log(newProcedures)
    
    const price = matchedItem ? (user.type === "NABH" ? matchedItem.nabh_amount : matchedItem.non_nabh_amount) : null; // or any default value you prefer
  console.log(price)
  newProcedures[index]["price"] = price;
  
    setProcData((prevData) => ({
      ...prevData,
      procedures: newProcedures,
    }));
    // Hide suggestions after selection
    setShowSuggestions(false);
  };


   // Generate a random number from 1 to 30
   const getRandomNumber = () => Math.floor(Math.random() * 30) + 1;

   useEffect(() => {
     // Update opd_number in data with the random number on mount
     setData((prevData) => ({
       ...prevData,
       opd_number: getRandomNumber().toString(),
       
     }));
   }, []); 
  // Empty dependency array to run only once on mount

   useEffect(() => {
    // Fetch all previous bills when the component mounts
    

    // Generate a random number from 1 to 30
    

    // Set the previous bill number if available
    if (prevBill.length > 0) {
      const lastBill = prevBill[prevBill?.length - 1];
      setPreBillNumber(Number(lastBill?.bill_number) +1);

      // Set the initial value of data.bill_number
      setData((prevData) => ({
        ...prevData,
        bill_number: Number(lastBill.bill_number) +1 ,
      }));
    }
  }, [prevBill]);

 
  

  return (
    <>
      <Container>
        <div className="container-fluid bgcolor min-vh-100">
          <div className="row">
            {toggle && (
              <div className="col-2 bg-dark vh-100 position-fixed">
                <Sidebar />
              </div>
            )}
            {toggle && <div className="col-2"></div>}
            <div className="col mrg">
              <NavDash Toggle={Toggle} />
              <div className="container mt-5 pb-5">
                {showProcedure === false ? (
                  <>
                    <h4>Add Pateint Details</h4>
                    <form onSubmit={createBillHandler}>
                      <div className="boxfirst rounded bg-white p-3">
                        <div className="row">
                          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                            <div class="mb-3">
                              <label for="email" class="form-label fw-bold">
                                Department type
                              </label>
                              <select
                                class="form-control"
                                id="email"
                                name="dept_type"
                                value={data.dept_type}
                                onChange={dataChange}
                                required
                              >
                                <option value="" disabled>
                                  --select--
                                </option>
                                <option value="OPD">OPD</option>
                                <option value="IPD">IPD</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                            <div class="mb-3">
                              <label
                                for="reg-number"
                                class="form-label fw-bold"
                              >
                                Registration Number
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="registeration number"
                                name="reg_number"
                                required
                                value={data.reg_number}
                                onChange={dataChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                            {data.dept_type === "OPD" ? (
                              <>
                                {" "}
                                <div class="mb-3">
                                  <label
                                    for="opd-number"
                                    class="form-label fw-bold"
                                  >
                                    OPD Number
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="opd-number"
                                    name="opd_number"
                                    value={data.opd_number}
                                    onChange={dataChange}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div class="mb-3">
                                  <label
                                    for="opd-number"
                                    class="form-label fw-bold"
                                  >
                                    IPD Number
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="ipd-number"
                                    name="ipd_number"
                                    value={data.ipd_number}
                                    onChange={dataChange}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          {/* <div className="col-lg-3 col-md-3 col-sm-12 col-12"></div> */}
                          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                            <div class="mb-3">
                              <label
                                for="bill-number"
                                class="form-label fw-bold"
                              >
                                Bill Number
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="bill-number"
                                value={data.bill_number}
                                required
                                name="bill_number"
                                onChange={dataChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                            <div class="mb-3">
                              <label
                                for="Pateint-Name"
                                class="form-label fw-bold"
                              >
                                Pateint Name
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Pateint-Name"
                                value={data.patient_name}
                                required
                                name="patient_name"
                                onChange={dataChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                            <div class="mb-3">
                              <label for="age" class="form-label fw-bold">
                                Age
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="enter age"
                                required
                                value={data.age}
                                name="age"
                                onChange={dataChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-3 col-sm-12 col-12">
                            <div class="mb-3">
                              <label for="gender" class="form-label fw-bold">
                                Gender
                              </label>
                              <select
                                class="form-control"
                                id="gender"
                                name="gender"
                                value={data.gender}
                                required
                                onChange={dataChange}
                              >
                                <option>--select--</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                            <div class="mb-3">
                              <label for="bill-date" class="form-label fw-bold">
                                Bill Date
                              </label>
                              <div class="">
                                <DatePicker
                                  selected={data.bill_date}
                                  onChange={(date) => handleDateChange(date)}
                                  className="form-control"
                                  required
                                  dateFormat="dd-MM-yyyy"
                                  placeholderText="Select a date"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                            <div class="mb-3">
                              <label
                                for="Beneficiary-Id"
                                class="form-label fw-bold"
                              >
                                Beneficiary Id
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Beneficiary Id"
                                required
                                value={data.beneficiary_Id}
                                name="beneficiary_Id"
                                onChange={dataChange}
                              />
                            </div>
                          </div>
                          {/* <div className="col-lg-6 col-md-6 col-sm-12 col-12"></div> */}
                          <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                            <div class="mb-3">
                              <label
                                for="Date-treatment"
                                class="form-label fw-bold"
                              >
                                Date of treatment
                              </label>
                              <div class="">
                                <DatePicker
                                  selected={data.date_of_treatment}
                                  onChange={(date) =>
                                    handleDateOfTreatmentChange(date)
                                  }
                                  className="form-control"
                                  dateFormat="dd-MM-yyyy"
                                  required
                                  placeholderText="Select a date"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="mb-3">
                              <label for="address" class="form-label fw-bold">
                                Address
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Enter your Address"
                                name="address"
                                required
                                value={data.address}
                                onChange={dataChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                            <div class="mb-3">
                              <label
                                for="referrel-id"
                                class="form-label fw-bold"
                              >
                                Refferal ID
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Refferal ID"
                                name="refferal_id"
                                required
                                value={data.refferal_id}
                                onChange={dataChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                            <div class="mb-3">
                              <label
                                for="doctor-name"
                                class="form-label fw-bold"
                              >
                                Doctor Name
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Doctor Name"
                                name="doctor_name"
                                required
                                value={data.doctor_name}
                                onChange={dataChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                            <div class="mb-3">
                              <label
                                for="bill-prepared-by"
                                class="form-label fw-bold"
                              >
                                Bill Prepared By
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Bill Prepared By"
                                required
                                value={data.bill_prepared_by}
                                name="bill_prepared_by"
                                onChange={dataChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12 col-12"></div>
                        </div>
                        <div>
                          <button type="submit" class="btn btn-primary">
                            Submit
                          </button>
                        </div>

                        {/* <button type="submit" class="btn btn-primary">
                    Add Patient
                  </button> */}
                      </div>
                    </form>
                  </>
                ) : (
                  <>
                    <form onSubmit={procedureHandler}>
                      <h4 className="mt-2 mb-2">Add Procedure Details</h4>
                      <div className="boxsecond rounded bg-white p-3">
                        <div className="boxsecond rounded bg-white p-3">
                          {procData.procedures.map((procedure, index) => (
                            <div className="row" key={index}>
                              <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                                <div className="mb-3">
                                  <label
                                    htmlFor={`Procedure-${index}`}
                                    className="form-label fw-bold"
                                  >
                                    Select Procedure
                                  </label>
                                  <input
                                    type="search"
                                    className="form-control"
                                    id={`Procedure-${index}`}
                                    placeholder="Select Procedure"
                                    value={procedure.procedure}
                                    required
                                    onChange={(e) => {
                                      handleChangeProcedure(
                                        index,
                                        "procedure",
                                        e.target.value
                                      );
                                      // Show suggestions when there is some input
                                      setShowSuggestions(
                                        e.target.value.trim().length > 0
                                      );
                                    }}
                                  />
                                  <Suggestions
                                    filteredSuggestions={filteredSuggestions}
                                    onSelect={(selectedProcedure) =>
                                      handleSelectProcedure(
                                        focusedInputIndex,
                                        selectedProcedure
                                      )
                                    }
                                    maxSuggestions={5}
                                    showSuggestions={showSuggestions  && focusedInputIndex == index}
                                    setShowSuggestions={setShowSuggestions}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                <div className="mb-3">
                                  <label
                                    htmlFor={`Procedure-price-${index}`}
                                    className="form-label fw-bold"
                                  >
                                    Procedure Price
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id={`Procedure-price-${index}`}
                                    placeholder="Enter Procedure Price"
                                    value={procedure.price}
                                    required
                                    onChange={(e) =>
                                      handleChangePrice(
                                        index,
                                        "price",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                <div className="mb-3">
                                  <label
                                    htmlFor={`Procedure-Quantity-${index}`}
                                    className="form-label fw-bold"
                                  >
                                    Procedure Quantity
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id={`Procedure-Quantity-${index}`}
                                    placeholder="Enter Procedure Quantity"
                                    value={procedure.quantity}
                                    required
                                    onChange={(e) =>
                                      handleChangeQuantity(
                                        index,
                                        "quantity",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                           
                              <div className="col-lg-1 col-md-1 col-sm-12 col-12 divsettle">
                                <button
                                  className="btn btn-success"
                                  type="button"
                                  onClick={() => handleAddProcedure()}
                                  style={{
                                    display:
                                      index === procData.procedures.length - 1
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  +
                                </button>
                                {index > 0 && (
                                  <button
                                    className="btn btn-danger"
                                    type="button"
                                    onClick={() => handleRemoveProcedure(index)}
                                  >
                                    x
                                  </button>
                                )}
                              </div>
                              <div className="col-lg-1 col-md-1 col-sm-12 col-12 divsettle"></div>
                            </div>
                          ))}
                        </div>

                        <div>
                          <button type="submit" class="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default AddSinglePatient;
const Container = styled.div`
  .bgcolor {
    background-color: #80bef5;
  }

  .divsettle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.8rem;
  }

  li {
    list-style: none;
  }
`;
