import React from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { useDispatch} from "react-redux";
import { clearUser } from "../redux/slices/UserSlices";
import {  useNavigate } from "react-router-dom";

const NavDash = ({ Toggle }) => {

  const dispatch = useDispatch();
  const  navigate = useNavigate();

  const logout = () =>{
   // Display a confirmation popup
   const isConfirmed = window.confirm('Are you sure you want to Logout?');

   if (!isConfirmed) {
     // If the user cancels the deletion, do nothing
     return;
   }

    dispatch(clearUser())
    navigate("/")
  }
  return (
    <>
      <Container>
        <nav className="navbar navbar-expand-sm navbar-dark bg-transparent shadow rounded mb-5">
          <div className="container-fluid">
            <a className="navbar-brand p-2" href="#" onClick={Toggle}>
              <span className="navbar-toggler-icon bg-dark rounded"></span>
            </a>
            <button
              className="navbar-toggler d-lg-none"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                <li className="nav-item dropdown">
                  <NavLink
                    className="nav-link text-white"
                    aria-current="page"
                    to="/admin-dashboard"
                    activeClassName="active"
                  >
                    Dashboard
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/header-footer"
                    activeClassName="active"
                  >
                    Add Header/Footer
                  </NavLink>
                </li> */}
              
                    <li>
                      <NavLink
                        className="nav-link text-white"
                        activeClassName="active"
                        to="/add-single-patient"
                      >
                        Create New Bill
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link text-white"
                        activeClassName="active"
                        to="/see-leads"
                      >
                        See All Bills
                      </NavLink>
                    </li>
                
                <li className="nav-item">
                  <NavLink className="nav-link text-white" to="/help" activeClassName="active">
                    Help
                  </NavLink>
                </li>
                <li>
                  <NavLink className="nav-link text-white" activeClassName="active"  onClick={logout}>
                    Logout
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </Container>
    </>
  );
};

export default NavDash;
const Container = styled.div``;
