import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../Layout/Layout";
import { FiPhoneCall, FiTwitter } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebookSquare, FaInstagram, FaMapMarkerAlt } from "react-icons/fa";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import MapLocation from "../component/MapLocation";
import cogoToast from "cogo-toast";

const Contact = () => {
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [mobile, setMobile] = useState("");
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post("https://wojindia.com/api/auth/inquiryMail", {
        name: fullname,
        email,
        message,
        number: mobile,
      });
      cogoToast.success("Message sent Successfully !");
      setFullName("");
      setEmail("");
      setMessage("");
      setMobile("");
      // navigate("/about");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Container>
        <div
          className="body-contact"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <div className="container contact-body">
            <div className="contact-form contact-body-less">
              <div className="row mt-5">
                {/* <div className="col-2"></div> */}
                <div className="col-12">
                  <h1>Contact Info</h1>

                  <p className="mt-3 g-5 text-start">
                    <span>
                      <FiPhoneCall className="svgcontact" />
                    </span>{" "}
                    <a href="tel:7771090999">+91-7771090999</a>
                    
                  </p>
                  <p className="text-start">
                    <span>
                      <AiOutlineMail className="svgcontact" />
                    </span>{" "}
                    <a href="mailto:contact@cghsbilling.com">
                    contact@cghsbilling.com
                    </a>
                  </p>
                  <p className="text-start">
                    <span>
                      <FaMapMarkerAlt className="svgcontact" />
                    </span>{" "}
                    1852, Wright town, Jabalpur - 482002 (M.P.)
                  </p>
                </div>
                {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <div className="container">
                    <h2 className="text-start">Have a query?</h2>
                    <form onSubmit={onSubmit}>
                      <div class="mb-3">
                        <input
                          type="Name"
                          class="form-control p-3"
                          aria-describedby="emailHelp"
                          placeholder="Enter your Name"
                          id="fullname"
                          value={fullname}
                          required
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </div>
                      <div class="mb-3">
                        <input
                          type="email"
                          class="form-control p-3"
                          placeholder="Enter your Email"
                          id="email"
                          value={email}
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div class="mb-3">
                        <input
                          type="text"
                          class="form-control p-3"
                          placeholder="Enter your mobile number without country code"
                          id="mobile"
                          value={mobile}
                          maxLength={10}
                          required
                          onChange={(e) => setMobile(e.target.value)}
                        />
                      </div>
                      <textarea
                        class="form-control mb-3 p-3"
                        placeholder="Enter your message"
                        id="message"
                        value={message}
                        required
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                      <button
                        type="submit"
                        class="btn btn-primary w-100 subBtn border"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Contact;
const Container = styled.div`
  .subBtn {
    background-color: #00c2b2;
  }

  .socialcontact > li {
    list-style: none;
  }

  .socialImagenav {
    height: 2rem;
    width: 2rem;
    margin: 1rem;
  }

  .socialcontact {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact-form {
    background-color: transparent;
  }

  .widthsett {
    width: auto;
    margin-left: 0rem;
  }

  .contact-body {
    /* box-shadow: 0px 8px 25px #4ebe93;
    border-radius: 7px; */
  }

  .svgcontact {
    color: #141716;
    &:hover {
      color: #000;
    }
  }

  .contact-body-less {
    display: flex;
    justify-content: space-evenly;
  }

  /* .body-contact {
  background-color: #c1c1c1;
  position: absolute;
  z-index: 1;
} */

  @media screen and (max-width: 542px) {
    .contact-body-less {
      text-align: center;
    }
    .socialcontact {
      display: flex;
      justify-content: space-around;
    }
    .widthsett {
      width: auto;
      margin-left: 0rem;
    }
  }

  .qrbox {
    img {
      height: auto;
      width: 10rem;
    }
  }
  .qrcont {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
  }
  a {
    text-decoration: none;
    color: #fff;
    &:hover {
      color: #000;
    }
  }
`;



