import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
// import Layout from "../Layout/Layout";
import Login from "../Components/Login";
import Header from "../Layout/Header";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Test from "../Components/Test";
import Contact from "../Components/Contact";
import ForgotPassword from "../Components/ForgotPass";

const Homepage = () => {
  const initialTab = localStorage.getItem("selectedTab") || "tab1";
  const [selectedTab, setSelectedTab] = useState(initialTab);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("selectedTab", selectedTab);
  }, [selectedTab]);
  return (
    <>
      {/* <Layout title={"CGHS Billing Home"}> */}
      <Header />
      <Container>
        <div className="container-fluid vh-100 pt-5">
          <div className="formbox">
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"></div>
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                {/* <Login /> */}
                <div className="container-fluid d-flex flex-column navsect align-items-center">
                  <Nav
                    className="flex-row side-cont"
                    activeKey={selectedTab}
                    onSelect={(selectedKey) => setSelectedTab(selectedKey)}
                  >
                    {/* <img
                      src="https://res.cloudinary.com/antrix/image/upload/v1691994477/woj/logowoj_iezamm.png"
                      alt="logo"
                      width={200}
                      className="mb-5 logoimg"
                    /> */}

                    <Nav.Item>
                      <Nav.Link eventKey="tab1" className="navlink">
                        Login
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="tab2" className="navlink">
                        Forgot Password
                      </Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link eventKey="tab3" className="navlink">
                        Contact/Enquiry
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="tab4" className="navlink">
                        Registered Student List
                      </Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                  <div className="flex-grow-1 p-3 mainback">
                    {selectedTab === "tab1" && <Login />}
                    {selectedTab === "tab2" && <ForgotPassword/>}
                    {selectedTab === "tab3" && <Contact />}
                    {/* {selectedTab === "tab4" && <RegStudentDetails />} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      {/* </Layout> */}
    </>
  );
};

export default Homepage;
const Container = styled.div`
  background-image: url("https://res.cloudinary.com/dq5upuxm8/image/upload/v1701166988/CGHS/big_bg2_zu9jag.jpg");
  background-position: center;
  background-size: cover;

  .navsect {
    background-color: #00c2b2;
    border-radius: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0px 2px 15px #706969;
  }

  .active {
    background-color: #0dcaf0;
    border-radius: 1rem;
  }

  .navlink {
    color: #fff;
    &:hover {
      color: #000;
    }
  }
`;
