import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Sidebar from "../AdminDashboard/Sidebar";
import NavDash from "../AdminDashboard/NavDash";
import { useSelector } from "react-redux";
import Cards from "../Components/Cards";
import HospitalStatistics from "../Components/HospitalStatistics";
import cogoToast from "cogo-toast";
import axios from "axios";

const Dashboard = () => {
  const user = useSelector((state) => state.user);
  console.log(`User Name: ${user.name}, User ID: ${user.id}`);
  console.log("User State:", user);

  const [toggle, setToggle] = useState(true);
  const Toggle = () => {
    setToggle(!toggle);
  };

  const [data, setData] = useState([]);
  const [procedure, setProcedure] = useState([]);
  const [patientProcedure, setPatientProcedure] = useState([]);



  const getAllBill = async () => {
    try {
      const response = await axios.get(
        `https://cghsbilling.com/api/v1/auth/getAllPatientBill/${user.id}`
      );
      console.log(response?.data);
      setData(response?.data);
    } catch (error) {
      console.log(error);
      cogoToast.error("error in getting bills");
    }
  };

  const getProcedures = async () => {
    const response = await axios.get(
      "https://cghsbilling.com/api/v1/auth/getAllProcedures"
    );
    console.log(response);
    setProcedure(response.data.result);
  };

  
  const getPatientProcedures = async () => {
    const response = await axios.get(
      "https://cghsbilling.com/api/v1/auth/getpatientProcedure"
    );
    console.log(response);
    setPatientProcedure(response.data.result);
  };

  useEffect(() => {
    getAllBill();
    getProcedures();
    getPatientProcedures();

  }, []);
  




  return (
    <>
      <Container>
        <div className="container-fluid min-vh-100">
          <div className="row">
            {toggle && (
              <div className="col-2 clrblack vh-100 position-fixed">
                <Sidebar />
              </div>
            )}
            {toggle && <div className="col-2"></div>}
            <div className="col mrg">
              <div className="bgcolor">
              <NavDash Toggle={Toggle} />
              </div>
              
              <div className="row">
   <div className="col-lg-12">
    <h3 className='hdd'>Dashboard
    <h6 className='mt-2'>Welcome <span className="text-capitalize">{user.name}</span> Admin</h6>
   </h3>
   </div>
   <div className="col-lg-12">
    <Cards billData={data} procedure={procedure} patientProcedure={patientProcedure}/>
   </div>
   <div >
   <HospitalStatistics billData={data} procedure={procedure} patientProcedure={patientProcedure}/>
   </div>
  




  </div>
              
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Dashboard;
const Container = styled.div`
overflow-x: hidden;
  .sidebar {
    width: 220px;
  }
  .mrg {
    padding: 0rem !important;
  }

  .bgcolor {
    background-color: #80bef5;
  }

  .clrblack {
    background-color: black !important;
  }
  .row{
  @media screen and (max-width: 768px) {
   
  margin-bottom: 10px;
}

}
.hdd{
    
    margin-left: 2rem;
    @media screen and (max-width: 768px) {
   
       
    margin-left: 2rem;
    }
}
`;
