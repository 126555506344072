import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: { name: "", id: null, type:"" ,address:"",contact:"",email: ""},
  reducers: {
    setUser: (state, action) => {
      state.name = action.payload.name;
      state.id = action.payload.id;
      state.type = action.payload.type;
      state.address = action.payload.address;
    state.contact = action.payload.contact;
    state.email = action.payload.email;
    },
    clearUser: (state) => {
      state.name = "";
      state.id = null;
      state.type = "";
      state.address = "";
      state.contact = "";
      state.email = "";


    },
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
