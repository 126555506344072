import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { BsPeople } from "react-icons/bs";
import { BsPersonPlus } from "react-icons/bs";
import { FaRupeeSign } from "react-icons/fa";
import { useSelector } from "react-redux";


function Cards({billData,procedure,patientProcedure}) {

  const user = useSelector((state) => state.user);
  let totalAmount = 0;
  let todayBillAmount = 0;
  
  console.log(billData)
  console.log(procedure)
  console.log(patientProcedure)
  // Filter today registered patients
  const todayRegistered = billData.filter((item) => {
    // Assuming item.bill_date is a valid date string or Date object
    const billDate = new Date(item.bill_creation_time);
    const today = new Date();
    return (
      billDate.getDate() === today.getDate() &&
      billDate.getMonth() === today.getMonth() &&
      billDate.getFullYear() === today.getFullYear()
    );
  });

  // if(billData){
  //   billData?.map((bill)=>{
    
      
  //     patientProcedure
  //     ?.filter((item) => item.patient_id === bill.bill_id)
  //     ?.map((filteredItem, index) => {
  //       const matchedProcedure = procedure.find(
  //         (procedureItem) => procedureItem.procedure_name.trim() === filteredItem.procedure_name.trim()
  //       );
  
  //       // Initialize totalAmount
        
        
        
  //       // Display amount if procedure is found

  //       if (matchedProcedure) {
          
  //         totalAmount += user.type === "NABH"
  //           ? ((Number(matchedProcedure?.nabh_amount )) * Number(filteredItem?.quantity))
  //           : ((Number(matchedProcedure?.non_nabh_amount )) * Number(filteredItem?.quantity));
  //           console.log((totalAmount))
            
  //       }
        
        
        
  //   })
    
  // })
  // }

  if (billData) {
    billData?.forEach((bill) => {
      
      const row = patientProcedure.filter((row) => row.patient_id === bill.bill_id);
      if(row[0]?.price){
         row.map(
          (row) => {
            totalAmount += (Number(row.price) * Number(row.quantity)) 
           
          }
         )
      }
     else{

 
      patientProcedure
        ?.filter((item) => item.patient_id === bill.bill_id)
        ?.forEach((filteredItem, index) => {
          const matchedProcedure = procedure.find(
            (procedureItem) => procedureItem.procedure_name.trim() === filteredItem.procedure_name.trim()
          );
  
          if (matchedProcedure) {
            // Initialize totalAmount
            
  
            // Check if both amount and quantity are valid numbers
            const nabhAmount = Number(matchedProcedure?.nabh_amount);
            const nonNabhAmount = Number(matchedProcedure?.non_nabh_amount);
            const quantity = Number(filteredItem?.quantity);
  
            if (!isNaN(nabhAmount) && !isNaN(nonNabhAmount) && !isNaN(quantity)) {
              // Perform calculations based on user type
              totalAmount += user.type === "NABH"
                ? (nabhAmount * quantity)
                : (nonNabhAmount * quantity);
  
              
            } else {
              console.error("Invalid amount or quantity:", nabhAmount, nonNabhAmount, quantity);
            }
          }
        });
      }
    });
  }
  
  
  if(todayRegistered){
    todayRegistered?.map((bill)=>{
    
      const row = patientProcedure.filter((row) => row.patient_id === bill.bill_id);
      if(row[0]?.price){
         row.map(
          (row) => {
            todayBillAmount += (Number(row.price) * Number(row.quantity)) 
          
          }
         )
      }
      else{


      patientProcedure
      ?.filter((item) => item.patient_id === bill.bill_id)
      ?.map((filteredItem, index) => {
        const matchedProcedure = procedure?.find(
          (procedureItem) => procedureItem.procedure_name.trim() === filteredItem.procedure_name.trim()
        );
  
        // Initialize totalAmount
        
        console.log(matchedProcedure)
        
        // Display amount if procedure is found
        if (matchedProcedure) {
          // todayBillAmount += user.type === "NABH"
          //   ? ((Number(matchedProcedure?.nabh_amount )) * Number(filteredItem?.quantity))
          //   : ((Number(matchedProcedure?.non_nabh_amount )) * Number(filteredItem?.quantity));
          //   console.log((matchedProcedure))

          
            // Check if both amount and quantity are valid numbers
            const nabhAmount = Number(matchedProcedure?.nabh_amount);
            const nonNabhAmount = Number(matchedProcedure?.non_nabh_amount);
            const quantity = Number(filteredItem?.quantity);
  
            if (!isNaN(nabhAmount) && !isNaN(nonNabhAmount) && !isNaN(quantity)) {
              // Perform calculations based on user type
              todayBillAmount += user.type === "NABH"
                ? (nabhAmount * quantity)
                : (nonNabhAmount * quantity);
  
              console.log(totalAmount);
            } else {
              console.error("Invalid amount or quantity:", nabhAmount, nonNabhAmount, quantity);
            }
        }
        
        
        
    })
  }
    
  })

  }
  
  console.log(todayBillAmount)
  
  







  useEffect(() => {

  }, []);
    
  return (
    <Wrapper>
    <div className="row" id='cardrow'>
        
    
        <div className="col-lg-3 col-md-12">
          <div className="card1" id="card2">
            <div className="card-body d-flex justify-content-center flex-column">
              <i className="bi  bi-building h1"></i>
             
              <div className="">
                <h5 className="card-title text-center " >
                 Total Patient
                </h5>

                <h4 className=" text-center text-dark">{billData?.length || 0}</h4>
                

               {/* <Link to='/allproperties' className="text-decoration-none"> <p className=" h6 text-center">View Detail</p></Link> */}
              </div>
              <div className='icon' style={{ color: " #5a626d" }}>
              <BsPeople className='icon'/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12">
          
          <div className="card2" id="card3">
            <div className="card-body d-flex justify-content-center flex-column">
            <i className="bi bi-file-post  h1 float-end"></i>
            
              <div className="">
                <h5 className="card-title text-center">
             Today Reg Patient
                </h5>

                <h4 className=" text-center text-dark">  <i className="bi "></i>{todayRegistered?.length}</h4>

                {/* <Link to='/registered_user' className="text-decoration-none">   <p className=" h6 text-center ">View Detail </p></Link> */}
              </div>

              <div className='icon' style={{ color: " #5a626d" }}>
            
              <BsPersonPlus className='icon'/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12">
          <div className="card3" id="card4">
            <div className="card-body d-flex justify-content-center flex-column">
              <i className="bi bi-people-fill h1"></i>
              <div className="">
                <h5 className="card-title text-center" >Total Bill Amount</h5>

                <h4 className=" text-center text-dark">{totalAmount}</h4>

                {/* <p className="view h6 text-center"><Link to="/interested_user" className="text-decoration-none" >View Detail</Link></p> */}
              </div >
              <div className='icon' style={{ color: " #5a626d" }}>
              
              <FaRupeeSign className='icon'/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12">
          <div className="card4" id="card2">

            <div className="card-body d-flex justify-content-center flex-column">
              <i className="bi bi-people-fill h1"></i>
              <div className="">
                <h5 className="card-title text-center" >Today Bill Amount</h5>

                <h4 className=" text-center text-dark">{todayBillAmount}</h4>

                {/* <p className="view h6 text-center"><Link to="/contact_user" className=" text-decoration-none" >View Detail</Link></p> */}
              </div>
              <div className='icon' style={{ color: " #5a626d" }}>
              
              <FaRupeeSign className='icon'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Cards
const Wrapper = styled.div`
     .dotrem {
    list-style-type: none;
  }
  .dotrem1 {
    list-style-type: none;
    width: 25px;
    padding-left:4px;
  }

.data{
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
 
}

 
  #card2 {
    width: 20rem;
    height: 10rem;
    margin-top: 5px;
    border-radius: 1rem;
    


    
    @media screen and (max-width: 768px) {
      margin-top: 1rem;
      width: 88%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      width: 41rem;
    }
    @media screen and (min-width: 1020px) and (max-width: 1600px) {
    width: 13rem;
  }
  }
  #card3 {
    width: 20rem;
    height: 10rem;
    
    margin-top: 5px;
    border-radius: 1rem;
    @media screen and (max-width: 768px) {
      width: 88%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      width: 41rem;
    }
    @media screen and (min-width: 1020px) and (max-width: 1600px) {
    width: 13rem;
  }
  }
  #card4 {
    width: 20rem;
    height: 10rem;
   
    margin-top: 5px;
    border-radius: 1rem;
    @media screen and (max-width: 768px) {
      width: 88%;
    }
    @media screen and (min-width: 768px) and (max-width: 1020px) {
      width: 41rem;
    }
    @media screen and (min-width: 1020px) and (max-width: 1600px) {
    width: 13rem;
  }
  }

  .view {
    color: red;
    
    
    
  }
  #cardrow{
    margin-top: 1rem;
    margin-left: 1rem;
    
    @media screen and (max-width: 768px) {
   
   gap: 1rem;
    
    }
    @media screen and (min-width: 1020px) and (max-width: 1600px) {
   margin-left: 1rem;
  }
  }
  .icon{
    height: 50px;
    width: 50px;
  }
  .card1{
    background-color: #FBAB7E;
background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
  }
  .card2{
    background-color: #85FFBD;
background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);

  }
  .card3{
    background-color: #FF9A8B;
background-image: linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%);

  }
  .card4{
    background-color: #A9C9FF;
background-image: linear-gradient(180deg, #A9C9FF 0%, #FFBBEC 100%);



  }
 

`