import React, { useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import cogoToast from "cogo-toast";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/slices/UserSlices";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  console.log(email, password);

  const login = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://cghsbilling.com/api/v1/auth/login-user",
        {
          email,
          password,
        }
      );

      console.log(response.data);
      if(response.data.success){
        Cookies.set("authToken", response.data.token, { expires: 7 });
        const userData = {
          name: response.data.user.name,
          id: response.data.user.id,
          type: response.data.user.type,
          address : response.data.user.address,
          contact : response.data.user.phone,
          email : response.data.user.email
  
        };
        localStorage.setItem("cghsData", JSON.stringify(userData));
        dispatch(setUser(userData));
        cogoToast.success("Login Successfull");
        navigate("/admin-dashboard");
      }
      else{
        cogoToast.error("error:", response?.data?.message); 
      }  
   
    } catch (error) {
      console.log(error);
      
 cogoToast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Container>
        <div>
          <div className="boxContainer">
            <div className="formcontent">
              <h2 className="text-start">Login</h2>
              <small>Enter your details below to continue</small>
              <form onSubmit={login}>
                <div className="mb-3">
                  {/* <label htmlFor="email" className="form-label">
                  Email
                </label> */}
                  <input
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  {/* <label htmlFor="password" className="form-label">
                  Password
                </label> */}
                  <input
                    type="password"
                    placeholder="Enter your password"
                    name="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <button className="btn btn-success" type="submit">
                    Submit
                  </button>
                </div>
              </form>
              {/* <p>
                Don't have an account?{" "}
                <span>
                  <Link to="/register">Signup</Link>
                </span>
              </p> */}
              <p className="text-center mt-2">
                
              
                  <Link to="/forgot-password">Forgot Password</Link>
              
              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Login;
const Container = styled.div`
  .boxContainer {
    //height: 100vh;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .formcontent {
      background-color: #0dcaf0;
      padding: 1rem;
      border-radius: 1rem;
      height: auto;
      box-shadow: 1px 2px 34px #38c7e4;
      h2 {
        text-align: center;
        font-family: monospace;
        margin: 1rem 0;
        color: #08494c !important;
      }
      span {
        a {
          text-decoration: none;
          color: #fff;
        }
      }
    }
  }
`;
