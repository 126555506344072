import React from "react";
// import Login from "./Components/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./Pages/Homepage";
// import Header from "./Layout/Header";
import Registration from "./Pages/Registration";
import Dashboard from "./AdminDashboard/Dashboard";
import HeadFoot from "./Pages/HeadFoot";
import AddSinglePatient from "./Pages/AddSinglePatient";
import SeeLeads from "./Pages/SeeLeads";
import UserHomePage from "./Pages/UserHomePage";
import { useSelector } from "react-redux";
import EditSinglePatient from "./Pages/EditSinglePatient";
import PrintReport from "./Pages/PrintReport";
import Profile from "./Pages/Profile";
import EditProfile from "./Pages/EditProfile";
import ForgotPassword from "./Components/ForgotPass";
import ResetPassword from "./Components/ResetPass";
import Contact from "./Components/Contact";
import Help from "./Components/Help";
import PrintWithoutLetterHead from "./Pages/PrintWithoutLetterHead";

const App = () => {
  const user = useSelector((state) => state.user);
  console.log(`User Name: ${user.name}, User ID: ${user.id}`);
  console.log("User State:", user);
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route
            path="/user-home"
            element={user.id === null ? <Homepage /> : <UserHomePage />}
          />
          <Route
            path="/help"
            element={user.id === null ? <Homepage /> : <Help/>}
          />
          <Route path="/register" element={<Registration />} />
          <Route
            path="/admin-dashboard"
            element={user.id === null ? <Homepage /> : <Dashboard />}
          />
          <Route
            path="/header-footer"
            element={user.id === null ? <Homepage /> : <HeadFoot />}
          />
          <Route
            path="/add-single-patient"
            element={user.id === null ? <Homepage /> : <AddSinglePatient />}
          />
          <Route
            path="/edit-single-patient/:billId"
            element={user.id === null ? <Homepage /> : <EditSinglePatient />}
          />
          <Route
            path="/print-report/:billId"
            element={user.id === null ? <Homepage /> : <PrintReport/>}
          />
          <Route
            path="/print-report-only/:billId"
            element={user.id === null ? <Homepage /> : <PrintWithoutLetterHead/>}
          />
          <Route
            path="/see-leads"
            element={user.id === null ? <Homepage /> : <SeeLeads/>}
          />
          <Route
            path="/hospital-profile"
            element={user.id === null ? <Homepage /> : <Profile/>}
          />
          <Route
            path="/update-profile"
            element={user.id === null ? <Homepage /> : <EditProfile/>}
          />
          <Route
            path="/forgot-password"
            element={ <ForgotPassword/>}
          />
          <Route
            path="/reset-password/:id/:token"
            element={ <ResetPassword/>}
          />


          {/* <Route
            path="/see-leads"
            element={user.id === null ? <Homepage /> : <SeeLeads />}
          /> */}
        </Routes>
      </Router>
    </>
  );
};

export default App;
