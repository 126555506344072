import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Header = () => {
  const user = useSelector((state) => state.user);
  console.log(`User Name: ${user.name}, User ID: ${user.id}`);
  console.log("User State:", user);
  return (
    <>
      <Container>
        <div>
          <nav class="navbar navbar-expand-lg navbar-light bg-light shadow">
            <div class="container-fluid">
              <Link class="navbar-brand" to="/">
                CGHS
              </Link>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <Link
                      class="nav-link active"
                      aria-current="page"
                      to="/user-home"
                    >
                      Home
                    </Link>
                  </li>
                  <li class="nav-item">
                    
                      
                      {/* <Link class="nav-link" to="/admin-dashboard">
                        Dashboard
                      </Link> */}
                    
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </Container>
    </>
  );
};

export default Header;
const Container = styled.div``;
