import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  RiAdminFill,
  RiDashboardFill,
  RiImageAddFill,
  RiHeartPulseFill,
  RiLogoutCircleRFill,
} from "react-icons/ri";

import { FaRegUser } from "react-icons/fa";

import { useDispatch} from "react-redux";
import { clearUser } from "../redux/slices/UserSlices";
import {  useNavigate } from "react-router-dom";

const Sidebar = () => {
  const dispatch = useDispatch();
  const  navigate = useNavigate();

  const logout = () =>{
    // Display a confirmation popup
    const isConfirmed = window.confirm('Are you sure you want to Logout?');
 
    if (!isConfirmed) {
      // If the user cancels the deletion, do nothing
      return;
    }
 
     dispatch(clearUser())
     navigate("/")
   }
  return (
    <>
      <Container>
        <div className="">
          <div className="mt-4 mb-5">
            <span className="brand-name text-light d-flex align-items-center">
              {/* <RiAdminFill /> <span className="textdis">Admin</span> */}
            </span>
          </div>

          <div className="list-group list-group-flush">
            <Link className="list-group-item py-2 my-1" to="/admin-dashboard">
              <span className="">
                <RiDashboardFill />
                <span style={{ marginLeft: "0.5rem" }} className="textdis">
                  Dashboard
                </span>
              </span>
            </Link>
            <Link className="list-group-item py-2 my-1" to="/hospital-profile">
              <span className="">
              <FaRegUser />
                <span style={{ marginLeft: "0.5rem" }} className="textdis">
                  Hospital Profile
                </span>
              </span>
            </Link>
            {/* <Link className="list-group-item py-2 my-1" to="/header-footer">
              <span className="">
                <RiImageAddFill />
                <span style={{ marginLeft: "0.5rem" }} className="textdis">
                  Add Header/Footer
                </span>
              </span>
            </Link> */}
            <div className="list-group">
              <p>
                <a
                  class="list-group-item py-2 my-1 bordernone"
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <RiHeartPulseFill />
                  <span style={{ marginLeft: "0.5rem" }} className="textdis">
                    Patient Details
                  </span>
                </a>
              </p>
              <div class="collapse" id="collapseExample">
                <hr />
                <Link
                  className="list-group-item bordernone"
                  to="/add-single-patient"
                >
                  <span className="textdis">Create New Bill</span>
                </Link>
                <hr />
                <Link className="list-group-item bordernone" to="/see-leads">
                  <span className="textdis">See All Bills</span>
                </Link>
              </div>
            </div>
            <div className="mt-5">
              <a className="list-group-item bordernone">
                <span className="" >
                  <RiLogoutCircleRFill />
                  <span style={{ marginLeft: "0.5rem" }} className="textdis" onClick={logout}>
                    Logout
                  </span>
                </span>
              </a>
              {/* <Link className="list-group bordernone mt-2" to="/user-home">
                <button className="btn btn-info btnweb">Back to website</button>
              </Link> */}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Sidebar;
const Container = styled.div`
  .list-group a:hover {
    background-color: #80bef5;
    color: white;
    cursor: pointer;
  }
  .bordernone {
    border: none;
  }
  li {
    list-style: none;
  }
  hr {
    margin: 0rem;
  }

  .list-group-item {
    position: relative;
    display: block;
    padding: 0.5rem 1rem;
    color: #f9fafb;
    text-decoration: none;
    background-color: transparent;
    border-bottom: 1px solid rgb(255 255 255 / 13%);
  }

  a {
    text-decoration: none;
  }

  .textdis {
    margin-left: 0.5rem;
    @media screen and (max-width: 500px) {
      display: none;
      margin: 0rem;
    }
  }

  .btnweb {
    @media screen and (max-width: 500px) {
      font-size: 10px;
      padding: 0rem;
    }
  }

  .brand-name {
    @media screen and (max-width: 500px) {
      font-size: 40px;
      padding: 0rem;
    }
  }
`;
