import React from "react";
import Header from "../Layout/Header";
import styled from "styled-components";
import { useSelector } from "react-redux";

const UserHomePage = () => {
  const user = useSelector((state) => state.user);
  console.log(`User Name: ${user.name}, User ID: ${user.id}`);
  console.log("User State:", user);
  return (
    <>
      <Container>
        <Header />
        <div className="container">
          <h1 className="text-center mt-3">Welcome {user.name}</h1>
          <div className="container-fluid mainbox">
            <div className="mt-3 rounded bg-white p-3">
              <h4 className="text-center fw-bold mb-3">Header Image</h4>
              <div className="headimage">
                <img
                  src="https://res.cloudinary.com/dq5upuxm8/image/upload/v1701166974/big_bg2_cusc9t.jpg"
                  alt="header"
                  srcset=""
                />
              </div>
            </div>
            <div className="mt-1 rounded bg-white p-3">
              <h4 className="text-center fw-bold mb-5">Footer Image</h4>
              <div className="headimage">
                <img
                  src="https://res.cloudinary.com/dq5upuxm8/image/upload/v1701166974/big_bg2_cusc9t.jpg"
                  alt="header"
                  srcset=""
                />
              </div>
            </div>
            <div className="mt-1 rounded bg-white p-3">
              <h4 className="text-center fw-bold mb-5">Seal Image</h4>
              <div className="headimage">
                <img
                  src="https://res.cloudinary.com/dq5upuxm8/image/upload/v1701166974/big_bg2_cusc9t.jpg"
                  alt="header"
                  srcset=""
                />
              </div>
            </div>
            <div className="mt-1 rounded bg-white p-3">
              <h4 className="text-center fw-bold mb-5">Sign Image</h4>
              <div className="headimage">
                <img
                  src="https://res.cloudinary.com/dq5upuxm8/image/upload/v1701166974/big_bg2_cusc9t.jpg"
                  alt="header"
                  srcset=""
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default UserHomePage;
const Container = styled.div`
  .headimage {
    height: 15rem;
    width: auto;

    img {
      height: 100%;
      width: 100%;
      border-radius: 1rem;
    }
  }
  h4 {
    text-shadow: 2px 6px 26px #000;
  }
`;
